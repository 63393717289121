import apiClient from "@/utils/api"
import { DELIVERY_TYPES } from "@/utils/constants"
import { defineStore } from "pinia"
export const useRequestStore = defineStore("RequestStore", {
  state: () => {
    return {
      incompleteRequests: [],
      checkoutData: {
        requests: [],
        deliveryOption: {
          deliveryName: DELIVERY_TYPES.ELECTRONIC,
          deliveryPrice: 0,
          isExpress: false,
          address: null,
        },
        billingAddress: null, // New field for billing address
        billingSameAsShipping: false,
        promoStatus: { valid: false, promocode: "", amountPrct: 0 },
        userData: null, // Add this line
        totalAmount: 0, // Add this line
      },
      completedRequests: [],
    }
  },
  actions: {
    setTotalAmount(totalAmount) {
      this.checkoutData.totalAmount = totalAmount
    },
    getTotalAmount() { return this.checkoutData.totalAmount },
    setUserData(userData) {
      // Add this method
      this.checkoutData.userData = userData
    },
    isCheckoutRequests(){
      // checks if any data recordered in checkoutdata and if yes, if there are any requests in requetsts
      return this.checkoutData.requests.length > 0
    },
    saveIncompleteRequest(payload) {
      const newRequest = {
        id: Date.now(),
        created_at: new Date().toISOString(), // Add creation timestamp
        requests: payload.data.map((request) => ({
          ...request,
          files: request.files.map((file) => ({
            name: file.name,
            originalName: file.name,
            size: file.size,
            type: file.type,
            file: file, // Store the actual file object
          })),
        })),
      }
      this.incompleteRequests.push(newRequest)
    },
    fetchIncompleteRequests() {
      return this.incompleteRequests
    },
    deleteIncompleteRequests() {
      this.incompleteRequests = []
    },
    updateIncompleteRequest(payload) {
      const index = this.incompleteRequests.findIndex(
        (req) => req.id === payload.id,
      )
      if (index !== -1) {
        this.incompleteRequests[index] = {
          ...this.incompleteRequests[index],
          requests: payload.data.map((request) => ({
            ...request,
            files: request.files.map((file) => ({
              name: file.name,
              originalName: file.name,
              size: file.size,
              type: file.type,
              file: file.file || file, // Keep the file object if it exists
            })),
          })),
        }
      }
    },
    setCheckoutDataRequests(payload) {
      console.log("Setting checkout data requests", payload)
      this.checkoutData.requests = payload
    },
    setCheckoutData(payload) {
      if (payload.deliveryOption) {
        this.checkoutData.deliveryOption = {
          deliveryName:
            payload.deliveryOption.deliveryName || DELIVERY_TYPES.ELECTRONIC,
          deliveryPrice: payload.deliveryOption.deliveryPrice || 0,
          isExpress: payload.deliveryOption.isExpress || false,
          address: payload.deliveryOption.address || {},
        }
      }
      this.checkoutData.promoStatus = payload.promoStatus
      if (payload.billingAddress) {
        this.checkoutData.billingAddress = payload.billingAddress
      }
      if (typeof payload.billingSameAsShipping !== "undefined") {
        this.checkoutData.billingSameAsShipping = payload.billingSameAsShipping
      }
    },
    async fetchCompletedRequests() {
      try {
        const response = await apiClient.get(
          "/service_request/service_requests",
        )
        if (response.data.message === "No service requests found") {
          this.completedRequests = []
        } else {
          this.completedRequests = response.data
        }
        this.fetchError = null
      } catch (error) {
        console.error("Error fetching completed requests:", error)
      }
    },
    clearCheckoutData(){
      this.checkoutData = {
        requests: [],
        deliveryOption: {
          deliveryName: DELIVERY_TYPES.ELECTRONIC,
          deliveryPrice: 0,
          isExpress: false,
          address: {},
        },
        promoStatus: { valid: false, promocode: "", amountPrct: 0 },
        userData: null,
      }
    },
    clearRequests(){
      this.completedRequests = []
      this.incompleteRequests = []
      this.checkoutData = {
        requests: [],
        deliveryOption: {
          deliveryName: DELIVERY_TYPES.ELECTRONIC,
          deliveryPrice: 0,
          isExpress: false,
          address: {},
        },
        promoStatus: { valid: false, promocode: "", amountPrct: 0 },
        userData: null,
      }
    }
  },
  persist: {
    enabled: true,
  },
})
