import router from "@/router"
import { useUserStore } from "@/stores/UserStore"
import { getTenantFromHostname } from "@/utils/tenant"
import axios from "axios"
import Cookies from "js-cookie"

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "http://backend:5000/api",
  withCredentials: true,
  xsrfCookieName: "csrf_access_token",
  xsrfHeaderName: "X-CSRF-TOKEN",
})
const shouldRefreshToken = () => {
  const token = Cookies.get("access_token_cookie")
  if (!token) return false

  try {
    const payload = JSON.parse(atob(token.split(".")[1]))
    const expiresIn = payload.exp - Math.floor(Date.now() / 1000)
    // Refresh if less than 5 minutes remaining
    return expiresIn < 300
  } catch (e) {
    return false
  }
}

apiClient.interceptors.request.use(async (config) => {
  const tenant = getTenantFromHostname()
  if (tenant) {
    config.headers["X-Tenant"] = tenant
  }
  const csrfToken = Cookies.get("csrf_access_token")
  if (csrfToken) {
    config.headers["X-CSRF-TOKEN"] = csrfToken
  }
  if (config.url === "/user/refresh") {
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = Cookies.get("csrf_refresh_token")
    }
  } else {
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = Cookies.get("csrf_access_token")
    }
  }

  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data"
  } else {
    config.headers["Content-Type"] = "application/json"
  }
  if (shouldRefreshToken() && config.url.endsWith("/user/refresh")) {
    try {
      await apiClient.post("/user/refresh", {})
    } catch (error) {
      // Handle refresh failure
      const userStore = useUserStore()
      await userStore.logout()
      router.push({ name: "LogIn" })
    }
  }

  // console.log("Request Headers:", config.headers)
  // console.log("Request Cookies:", Cookies.get())
  return config
})

let isLoggingOut = false

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("Response Error Status:", error.response?.status)
    console.log("Response Error Data:", error.response?.data)
    const originalRequest = error.config

    // Prevent infinite loops and check if we're not already logging out
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !isLoggingOut &&
      !originalRequest.url.endsWith("/user/refresh")
    ) {
      originalRequest._retry = true

      try {
        await apiClient.post(
          "/user/refresh",
          {},
          {
            headers: {
              "X-CSRF-TOKEN": Cookies.get("csrf_refresh_token"),
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
        )

        // Update CSRF token for original request
        originalRequest.headers["X-CSRF-TOKEN"] =
          Cookies.get("csrf_access_token")
        return apiClient(originalRequest)
      } catch (refreshError) {
        // Prevent multiple logout attempts
        if (!isLoggingOut) {
          isLoggingOut = true
          const userStore = useUserStore()
          await userStore.logout()
          router.push({ name: "LogIn" })
          isLoggingOut = false
        }
        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  },
)

export default apiClient
