import { useUserStore } from "@/stores/UserStore"
import { createRouter, createWebHistory } from "vue-router"
import { adminRoutes } from "./adminRoutes"
import { anonymousRoutes } from "./anonymousRoutes"
import { clientRoutes } from "./clientRoutes"
import { companyRoutes } from "./companyRoutes"
import { organizationRoutes } from "./organizationRoutes"
import { translatorRoutes } from "./translatorRoutes.js"
const routes = [
  ...adminRoutes,
  ...clientRoutes,
  ...companyRoutes,
  ...organizationRoutes,
  ...translatorRoutes,
  ...anonymousRoutes,

  {
    path: "/account-settings",
    name: "AccountSettings",
    component: () => import("@/views/base/AccountSettings.vue"),
    meta: {
      requiresAuth: true,
      role: ["client", "admin", "translator", "organization"],
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("@/views/base/Notifications.vue"),
    meta: {
      requiresAuth: true,
      role: ["client", "admin", "translator", "organization"],
    },
  },
  {
    path: "/pre-checkout",
    name: "PreCheckout",
    component: () => import("@/views/base/PreCheckout.vue"),
    meta: {
      requiresAuth: true,
      role: ["client", "organization"],
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("@/views/base/Checkout.vue"),
    meta: {
      requiresAuth: true,
      role: ["client", "organization"],
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("@/views/base/Payment.vue"),
    meta: {
      requiresAuth: true,
      role: ["client", "organization"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import("@/views/base/PageNotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const { requiresAuth, role, permissions, fallback } = to.meta
  if (!requiresAuth) {
    return next()
  }
  if (!userStore.user) {
    return next({ name: "LogIn" })
  }
  if (role && !role.includes(userStore.role)) {
    return next({ name: "PageNotFound" })
  }
  if (permissions && !userStore.hasPermissions(permissions)) {
    if (fallback) {
      return next({ name: fallback })
    }
    return next({ name: "PageNotFound" })
  }

  next()
})
export default router
