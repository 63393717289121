import apiClient from "@/utils/api"
import { defineStore } from "pinia"
export const useTenantStore = defineStore("tenant", {
  state: () => ({
    paletteColors: null,
    tenant: null,
    isLoaded: false,
    fontFamily: "Nunito, Helvetica",
    taxes: {},
  }),
  actions: {
    async loadTenantData(tenantName) {
      if (
        !this.isLoaded ||
        !this.palleteColors ||
        !this.taxes ||
        this.tenant !== tenantName
      ) {
        try {
          const response = await apiClient.get(
            `/company/by-tenant/${tenantName}`,
          )
          this.paletteColors = response.data.palette_colors
          this.taxes = response.data.taxes
          this.tenant = tenantName
          this.isLoaded = true
        } catch (error) {
          console.error("Failed to load tenant palette:", error)
        }
      }

      this.applyPalette()
    },
    async loadTaxes(tenantName) {
      if (!this.taxes || Object.keys(this.taxes).length === 0) {
        try {
          const response = await apiClient.get(
            `/company/by-tenant/${tenantName}`,
          )
          this.taxes = response.data.taxes
        } catch (error) {
          console.error("Failed to load taxes:", error)
          this.taxes = {} // Set default empty object on error
        }
      }
      return this.taxes // Return the taxes object directly, not the promise
    },
    applyPalette() {
      if (!this.paletteColors) return
      const root = document.documentElement
      root.style.setProperty("--main-color", this.paletteColors.main)
      root.style.setProperty("--accent-color", this.paletteColors.accent)
      root.style.setProperty(
        "--additional-color",
        this.paletteColors.additional,
      )
      root.style.setProperty(
        "--font-family",
        this.fontFamily || "Nunito, Helvetica",
      )
      // console.log("pallete applied", this.paletteColors)
    },
    setPaletteColors(newColors) {
      this.paletteColors = newColors
      this.applyPalette()
    },
    setFontFamily(newFont) {
      this.fontFamily = newFont || "Nunito, Helvetica"
      this.applyPalette()
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "tenant",
        storage: localStorage,
      },
    ],
  },
})
