<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { provideWindowSize } from "@/composables/useWindowSize"
import { useRequestStore } from "@/stores/RequestStore"
import { useTenantStore } from "@/stores/TenantStore"
import { useUserStore } from "@/stores/UserStore"
import { getTenantFromHostname } from "@/utils/tenant"
import { onBeforeUnmount, onMounted } from "vue"

export default {
  name: "App",
  setup() {
    provideWindowSize()
    const userStore = useUserStore()
    const requestStore = useRequestStore()
    const tenantStore = useTenantStore()

    onBeforeUnmount(async () => {
      requestStore.clearCompletedRequests()
    })
    onMounted(async () => {
      const tenantName = getTenantFromHostname() || "staging"
      await tenantStore.loadTenantData(tenantName)
    })

    return { userStore }
  }
}
</script>

<style></style>
