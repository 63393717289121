<template>
  <button class="filled-button">
    <span class="btn-txt">
      {{ btnText }}
    </span>
  </button>
</template>

<script>
export default {
  name: "FilledButton",
  props: {
    btnText: {
      type: String,
      default: "Add new"
    }
  }
}
</script>

<style lang="sass" scoped>
@use "@/assets/styles/index" as styles;


.filled-button {
  height: 50px;
  min-width: 200px;
  position: relative;
  background-color: styles.$maincolor;
  cursor: pointer;
  border: 2px solid styles.$maincolor;
  overflow: hidden;
  border-radius: 30px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  &:active{
      transform: scale(0.9);
  }
  &::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all .5s ease-in-out;
    background-color: styles.$additionalcolor;
    border-radius: 30px;
    visibility: hidden;
    height: 10px;
    width: 10px;
    z-index: 0;
  }
  &:hover{
    box-shadow: 2px 2px 50px styles.$additionalcolor;
    color: styles.$maincolor;
    &::after{
      visibility: visible;
      transform: scale(100) translateX(2px);
    }
  }
  .btn-txt {
    z-index: 1;
    letter-spacing: 4px;
    font-family: styles.$font-family;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 20px;
    position: relative;
    text-align: center;
    @media (min-width: 1440px){
      font-size: styles.$font-size-desktop-m;
    }
    @media (max-width: 1439px){
      font-size: styles.$font-size-mobile-m;
    }
  }
}
</style>
