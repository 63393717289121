import axios from "axios"
import { createPinia } from "pinia"
import persistedState from "pinia-plugin-persistedstate"
import { createApp } from "vue"
import App from "./App.vue"
import "./assets/styles/background.css"
import "./assets/styles/globals.sass"
import i18n from "./i18n"
import router from "./router"

axios.defaults.baseURL =
  process.env.VUE_APP_API_URL || "http://localhost:5000/api"

const pinia = createPinia()
pinia.use(persistedState)

const app = createApp(App)
app.use(router)
app.use(pinia)
app.use(i18n)
app.mount("#app")

if (module.hot) {
  module.hot.accept()
}
