export const translatorRoutes = [
  {
    path: "/translator/home",
    name: "TranslatorHome",
    component: () => import("@/views/translator/TranslatorHome.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
  {
    path: "/translator/job/:job_id",
    name: "TranslatorJob",
    component: () => import("@/views/translator/TranslatorJob.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
  {
    path: "/translator/translate-file-choose",
    name: "TranslatorTranslateFileChoose",
    component: () => import("@/views/translator/TranslationFileChose.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
  {
    path: "/translator/translate-overlap",
    name: "TranslatorTranslationOverlap",
    component: () => import("@/views/translator/TranslationOverlap.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
  {
    path: "/translator/translate-scanning",
    name: "TranslatorTranslationScanning",
    component: () => import("@/views/translator/TranslationScanning.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
  {
    path: "/translator/certification",
    name: "TranslatorCertification",
    component: () => import("@/views/translator/TranslationCertification.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
  {
    path: "/translator/payments",
    name: "TranslatorPayments",
    component: () => import("@/views/translator/TranslatorPayments.vue"),
    meta: { requiresAuth: true, role: "translator" },
  },
]
