import { createI18n } from "vue-i18n"
import en from "./locales/en.json"
import es from "./locales/es.json"

const messages = {
  en,
  es,
}
const userLocale = navigator.language.split("-")[0] // Get the language part only (e.g., 'en' from 'en-US')
const defaultLocale = messages[userLocale] ? userLocale : "en"

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
})

export default i18n
