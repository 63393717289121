<template>
  <div class="entry-page">
    <div class="main-box">
      <img
        class="logo"
        alt="Logo"
        :src="logo"
      />
      <div class="entry-page-text">
        {{ $t("entryPage.title") }}
        <br />
        {{ $t("entryPage.subtitle") }}
      </div>
      <div class="route-buttons">
        <div
          v-for="button in buttons"
          :key="button.text"
          class="button-box"
        >
          <div class="text-wrapper">
            {{ $t(button.text) }}
          </div>
          <FilledButton
            :btn-text="$t(button.btnText)"
            class="filled-button-instance"
            @click="navigateTo(button.route)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/lim-logo.png"
import FilledButton from "@/components/buttons/FilledButton.vue"
import { ref } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "EntryPage",
  components: {
    FilledButton
  },
  setup() {
    const router = useRouter()
    const navigateTo = async (routeName) => {
      try {
        await router.push({ name: routeName })
      } catch (error) {
        console.error("Navigation error:", error)
      }
    }

    const buttons = ref([
      {
        text: "entryPage.buttons.iKnow",
        btnText: "forms.buttons.createRequest",
        route: "Home"
      },
      {
        text: "entryPage.buttons.haveAccount",
        btnText: "forms.buttons.logIn",
        route: "LogIn"
      },
      {
        text: "entryPage.buttons.newHere",
        btnText: "forms.buttons.signUp",
        route: "SignUp"
      }
    ])

    return {
      logo,
      navigateTo,
      buttons
    }
  }
}
</script>

<style lang="sass" scoped>
@use "@/assets/styles/index" as styles;



.entry-page{
  @extend .page-container
}
.main-box{
  @extend .main-box;
  @extend .main-box-medium;
}
.logo{
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.entry-page-text{
  @extend .page-label;
  text-align: center;
}
.route-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  gap: 26px;
  @media (max-width: 1439px){
    width: 100%;
    gap: 10px;
  }
}
.button-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 22px;
  @media (max-width: 1439px){
    gap: 10px;
  }
}
.text-wrapper{
  color: styles.$accentcolor;
  font-family: styles.$font-family;
  font-weight: 700;
  text-align: center;
}
.filled-button-instance{
  width: 100%;
}
@media (min-width: 1440px){
  .main-box{
    margin-top: 145px;
    padding: 30px;
    gap: 10px;
  }
  .entry-page-text{
    font-size: styles.$font-size-desktop-xl;
  }
  .text-wrapper{
    font-size: styles.$font-size-desktop-m;
    line-height: 27px;
  }
}
@media (max-width: 1439px){
  .main-box{
    width: 100%;
    padding: 15px;
  }
  .entry-page-text{
    font-size: styles.$font-size-mobile-xl;
    line-height: 30px;
  }
  .text-wrapper{
    font-size: styles.$font-size-mobile-m;
    line-height: 24px;
  }
}
</style>
