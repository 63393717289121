import { ref, onMounted, onBeforeUnmount, provide, inject } from "vue"

const WINDOW_SIZE_KEY = Symbol("windowSize")

/**
 *
 */
export function provideWindowSize() {
  const windowWidth = ref(window.innerWidth)
  const isDesktop = ref(window.innerWidth >= 1440)

  const handleResize = () => {
    windowWidth.value = window.innerWidth
    isDesktop.value = window.innerWidth >= 1440
  }

  onMounted(() => {
    window.addEventListener("resize", handleResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize)
  })

  provide(WINDOW_SIZE_KEY, { windowWidth, isDesktop })
}

/**
 * @returns {object} The window size object.
 */
export function useWindowSize() {
  const windowSize = inject(WINDOW_SIZE_KEY)
  if (!windowSize) {
    throw new Error(
      "useWindowSize must be used within a provideWindowSize context"
    )
  }
  return windowSize
}
