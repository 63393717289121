import { useRequestStore } from "@/stores/RequestStore"
import apiClient from "@/utils/api"
import { defineStore } from "pinia"
import { ref } from "vue"
import { useRouter } from "vue-router"
export const useUserStore = defineStore(
  "user",
  () => {
    const user = ref(null)
    const role = ref("anonymous")
    const permissions = ref([])
    const pendingAmount = ref(0)
    const availableAmount = ref(0)
    const router = useRouter()
    const unreadNotifications = ref(false)

    const setUser = (userData) => {
      console.log("setting user with data", userData)
      user.value = userData
      role.value = userData["role"]
      permissions.value = userData["permissions"] || []
    }
    const clearUser = () => {
      user.value = null
      role.value = "anonymous"
      permissions.value = []
      pendingAmount.value = 0
      availableAmount.value = 0
    }
    const getUserRole = () => {
      return role
    }
    const getUserPermissions = () => {
      return permissions
    }
    const hasPermissions = (requiredPermissions) => {
      if (!requiredPermissions) return true
      if (!permissions.value.length) return false
      return requiredPermissions.every((p) => permissions.value.includes(p))
    }
    const fetchPayments = async () => {
      try {
        const response = await apiClient.get(
          `/job/jobs/payments/${user.value.id}`,
        )
        const payments = response.data
        console.log("Payments:", payments)
        pendingAmount.value =
          payments
            .filter((job) => job.payment_withdraw_status === "pending")
            .reduce((sum, job) => sum + parseFloat(job.price), 0)
            .toFixed(2) || 0
        availableAmount.value =
          payments
            .filter((job) => job.payment_withdraw_status === "available")
            .reduce((sum, job) => sum + parseFloat(job.price), 0)
            .toFixed(2) || 0

        console.log("Pending amount:", pendingAmount.value)
        console.log("Available amount:", availableAmount.value)
      } catch (error) {
        console.error("Error fetching payments:", error)
      }
    }
    const refreshUnreadNotifications = async () => {
      const response = await apiClient.get("/user/notifications/unread-count")
      unreadNotifications.value = response.data.unreadCount > 0
    }

    const login = async (email, password, token) => {
      try {
        const response = await apiClient.post("/user/login", {
          email: email,
          password: password,
          token: token,
        })
        console.log("Login response:", response)
        const userData = response.data.user
        setUser(userData)
        if (userData.role === "translator") {
          await fetchPayments()
        }
        return { role: userData.role }
      } catch (error) {
        if (error.response && error.response.data) {
          return { error: error.response.data.message }
        } else {
          return { error: "An unexpected error occurred" }
        }
      }
    }
    const register = async (formData) => {
      console.log("Registering with:", formData)
      try {
        await apiClient.post("/user/register", formData)
        return { success: true }
      } catch (error) {
        if (error.response && error.response.data) {
          return { error: error.response.data.message }
        } else {
          return { error: "An unexpected error occurred" }
        }
      }
    }

    const logout = async () => {
      try {
        await apiClient.post("/user/logout")
      } catch (error) {
        console.error("Logout failed:", error.response)
      } finally {
        const requestStore = useRequestStore()
        requestStore.clearRequests()
        clearUser()
        router.push({ name: "LogIn" })
      }
    }

    return {
      user,
      role,
      getUserRole,
      setUser,
      clearUser,
      login,
      logout,
      register,
      fetchPayments,
      pendingAmount,
      unreadNotifications,
      refreshUnreadNotifications,
      availableAmount,
      hasPermissions,
      permissions,
      getUserPermissions,
    }
  },
  {
    persist: {
      enabled: true,
    },
  },
)
