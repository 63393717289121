export const DELIVERY_PRICES = {
  electronic: 0,
  economy: 0,
  regular: 36.7,
  express: 36.7,
}
export const EXPRESS_FEES = {
  MIN_FEE: 39, // Minimum express fee
  MARKUP_PERCENTAGE: 0.25, // 25% markup for express
}
export const HARD_COPY_FEE = 19.95
export const VISA_DEFAULT_PRICE = 70.0
export const STAMP_DEFAULT_PRICE = 70.0
export const STAMP_EXTRA_PRICE = 10.95

export const REFERRAL_PARTNER_AT_THE_COUNTER_FEE = 16.80
export const REFERRAL_PARTNER_COMMISION_PCT = 0.06
export const DELIVERY_TYPES = {
  ELECTRONIC: "Electronic Delivery",
  ECONOMY: "Economy Service",
  REGULAR: "Regular Service",
  EXPRESS: "Translation Express",
}

export const JOB_OFFER_STATUS_MAPPING = {
  manually_assigned: "Manually assigned",
  pending: "Pending approval",
  accepted: "Accepted",
  expired: "Expired",
  declined: "Declined",
}
export const SERVICE_REQUEST_STATUS_MAPPING = {
  search: "Searching for translator",
  translating: "Translating",
  draft: "Draft ready",
  confirmed_draft: "Draft confirmed",
  returned_draft: "Draft returned",
  certified: "Certified",
  amendment: "Amendment",
  completed: "Completed",
}

export const DELIVERY_MAPPING = {
  "Electronic Delivery": {
    deliveryName: DELIVERY_TYPES.ELECTRONIC,
    deliveryPrice: 0,
    isExpress: false,
  },
  "Economy Service": {
    deliveryName: DELIVERY_TYPES.ECONOMY,
    deliveryPrice: 0,
    isExpress: false,
  },
  "Regular Service": {
    deliveryName: DELIVERY_TYPES.REGULAR,
    deliveryPrice: DELIVERY_PRICES.regular,
    isExpress: false,
  },
  "Translation Express": {
    deliveryName: DELIVERY_TYPES.EXPRESS,
    deliveryPrice: DELIVERY_PRICES.express, // Base price, express fee calculated separately
    isExpress: true,
  },
}

export const PURPOSE_MAPPING = {
  pr: "PR application",
  legal: "Personal Legal",
  education: "Education",
  immigration: "Immigration",
}

export const ROLES = {
  ADMIN: "admin",
  CLIENT: "client",
  TRANSLATOR: "translator",
  COMPANY: "company",
  ORGANIZATION: "organization",
  SUPERADMIN: "superadmin",
}

export const PERMISSIONS = {
  ORGANIZATION_ADMIN: "organization_admin",
  PRICING: "pricing",
  INVOICES: "invoices",
  BILLS: "bills",
  TRANSLATORS: "translators",
  ORGANIZATIONS: "organizations",
  USERS: "users",
  REPORTS: "reports",
  MANAGER: "manager",
}

export const NOTARIZATION_FEE = 85.00

export const MERCHANT_ID = '383613077'