export const companyRoutes = [
  {
    path: "/company/home",
    name: "CompanyHome",
    component: () => import("@/views/company/CompanyHome.vue"),
    meta: { requiresAuth: true, role: "company" },
  },
  {
    path: "/company/users",
    name: "CompanyUsers",
    component: () => import("@/views/company/CompanyUsers.vue"),
    meta: { requiresAuth: true, role: "company" },
  },
  {
    path: "/company/create-user",
    name: "CompanyCreateUser",
    component: () => import("@/views/company/CompanyCreateUser.vue"),
    meta: { requiresAuth: true, role: "company" },
  },

  {
    path: "/company/edit-user/:user_id",
    name: "CompanyEditUser",
    component: () => import("@/views/company/CompanyEditUser.vue"),
    meta: { requiresAuth: true, role: "company" },
  },
  {
    path: "/company/subscription",
    name: "CompanySubscription",
    component: () => import("@/views/company/CompanySubscription.vue"),
    meta: { requiresAuth: true, role: "company" },
  },
  {
    path: "/company/settings",
    name: "CompanySettings",
    component: () => import("@/views/company/CompanySettings.vue"),
    meta: { requiresAuth: true, role: "company" },
  },
]
