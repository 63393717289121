export const clientRoutes = [
  {
    path: "/client/home",
    name: "ClientHome",
    component: () => import("@/views/client/ClientHome.vue"),
    meta: {
      requiresAuth: true,
      role: ["client", "admin"],
    },
  },
  {
    path: "/client/request/create",
    name: "ClientRequestCreate",
    component: () => import("@/views/client/ClientServiceRequest.vue"),
    meta: { requiresAuth: true, role: "client" },
  },
  {
    path: "/client/request/edit/:service_request_group_id",
    name: "ClientRequestEdit",
    component: () => import("@/views/client/ClientEditRequest.vue"),
    meta: { requiresAuth: true, role: "client" },
  },
  {
    path: "/client/request/:service_request_group_id",
    name: "ClientRequest",
    component: () => import("@/views/client/ClientRequest.vue"),
    meta: { requiresAuth: true, role: "client" },
  },
  {
    path: "/track-request/:service_request_group_id",
    name: "TrackRequest",
    component: () => import("@/views/client/ClientRequest.vue"),
    props: (route) => ({ token: route.query.token }),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/edit-request/:service_request_group_id",
    name: "EditRequest",
    component: () => import("@/views/client/ClientEditRequest.vue"),
    props: true,
    meta: { role: ["anonymous"] },
  },
]
