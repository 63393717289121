export const adminRoutes = [
  {
    path: "/admin/home",
    name: "AdminHome",
    component: () => import("@/views/admin/AdminHome.vue"),
    meta: { requiresAuth: true, role: "admin" },
  },
  
  {
    path: "/admin/reports",
    name: "AdminReports",
    component: () => import("@/views/admin/AdminReports.vue"),
    meta: { requiresAuth: true, role: ["admin", "superadmin"], permissions: ["reports"] },
  },
  {
    path: "/admin/request/:service_request_group_id",
    name: "AdminRequest",
    component: () => import("@/views/admin/AdminRequest.vue"),
    meta: { requiresAuth: true, role: ["admin", "company"] },
  },
  {
    path: "/admin/invoices",
    name: "AdminInvoices",
    component: () => import("@/views/admin/AdminInvoiceList.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["invoices"] },
  },
  {
    path: "/admin/bills",
    name: "AdminBills",
    component: () => import("@/views/admin/AdminBills.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["bills"] },
  },
  {
    path: "/admin/bill/:bill_id",
    name: "AdminBill",
    component: () => import("@/views/admin/AdminBill.vue"),
    meta: { requiresAuth: true, role: "admin", permission: ["bills"] },
  },
  {
    path: "/admin/invoice/:invoice_id",
    name: "AdminInvoice",
    component: () => import("@/views/admin/AdminInvoice.vue"),
    meta: { requiresAuth: true, role: "admin", permission: ["invoices"] },
  },
  {
    path: "/admin/translators-management",
    name: "AdminTranslatorsManagement",
    component: () => import("@/views/admin/AdminTranslatorsManagement.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["translators"] },
  },
  {
    path: "/admin/users-management",
    name: "AdminUserManagement",
    component: () => import("@/views/admin/AdminUserManagement.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["users"] },
  },
  {
    path: "/admin/users-management/edit/:user_id",
    name: "AdminEditUser",
    component: () => import("@/views/admin/AdminEditUser.vue"),
    meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/admin/users-management/create-user",
    name: "AdminCreateUser",
    component: () => import("@/views/admin/AdminCreateUser.vue"),
    meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/admin/organizations-management",
    name: "AdminOrganizationManagement",
    component: () => import("@/views/admin/AdminOrganizationManagement.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["organizations"] },
  },
  {
    path: "/admin/register-organization",
    name: "AdminRegisterOrganization",
    component: () => import("@/views/admin/AdminRegisterOrganization.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["organizations"] },
  },
  {
    path: "/admin/edit-organization/:organization_id",
    name: "AdminEditOrganization",
    component: () => import("@/views/admin/AdminEditOrganization.vue"),
    meta: { requiresAuth: true, role: "admin", permission: ["organizations"] },
  },
  {
    path: "/admin/organization/:organization_id/create-organization-user",
    name: "AdminCreateOrganizationUser",
    component: () => import("@/views/admin/AdminCreateOrganizationUser.vue"),
    meta: { requiresAuth: true, role: "admin", permission: ["organizations"] },
  },
  {
    path: "/admin/edit-organization/:organization_id/user/:user_id",
    name: "AdminEditOrganizationUser",
    component: () => import("@/views/admin/AdminEditOrganizationUser.vue"),
    meta: { requiresAuth: true, role: "admin", permission: ["organizations"] },
  },
  {
    path: "/admin/pricing-management",
    name: "AdminPricingManagement",
    component: () => import("@/views/admin/AdminPricingManagement.vue"),
    meta: { requiresAuth: true, role: "admin", permissions: ["pricing"] },
  },
  {
    path: "/admin/request-create",
    name: "AdminServiceRequest",
    component: () => import("@/views/admin/AdminServiceRequest.vue"),
    meta: { requiresAuth: true, role: "admin" },
  },
]
