export const organizationRoutes = [
  {
    path: "/organization/home",
    name: "OrganizationHome",
    component: () => import("@/views/organization/OrganizationHome.vue"),
    meta: {
      requiresAuth: true,
      role: "organization",
      permissions: ["organization_admin"],
      fallback: "OrganizationTransactionForm",
    },
  },
  {
    path: "/organization/transaction-form",
    name: "OrganizationTransactionForm",
    component: () =>
      import("@/views/organization/OrganizationTransactionForm.vue"),
    meta: { requiresAuth: true, role: "organization" },
  },
  {
    path: "/organization/transaction/:service_request_group_id",
    name: "OrganizationTransaction",
    component: () => import("@/views/organization/OrganizationTransaction.vue"),
    meta: {
      requiresAuth: true,
      role: "organization",
      permissions: ["organization_admin"],
      fallback: "OrganizationTransactionForm",
    },
  },
]
