// frontend/src/utils/tenant.js
export function getTenantFromHostname() {
  const hostname = window.location.hostname
  if (hostname === "development.localhost" || hostname === "localhost") {
    return "staging"
  }
  const parts = hostname.split(".")
  if (parts.length > 2) {
    return parts[0] // Subdomain as tenant identifier
  }
  return null
}
