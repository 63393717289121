import EntryPage from "@/views/base/EntryPage.vue"
export const anonymousRoutes = [
  {
    path: "/",
    name: "EntryPage",
    component: EntryPage,
    meta: { role: ["anonymous"] },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/base/ServiceRequestStart.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import("@/views/base/LogIn.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("@/views/base/SignUp.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/base/ForgotPassword.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/base/ResetPassword.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/finalize-registration",
    name: "FinalizeRegistration",
    props: (route) => ({ token: route.query.token }),
    component: () => import("@/views/base/FinalizeRegistration.vue"),
    meta: { role: ["anonymous", "client"] },
  },
  {
    path: "/post-request-registration",
    name: "PostRequestRegistration",
    component: () => import("@/views/base/PostRequestRegistration.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/service-request-create",
    name: "ServiceRequestCreate",
    component: () => import("@/views/base/ServiceRequestDocuments.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/service-request-checkout",
    name: "ServiceRequestCheckout",
    component: () => import("@/views/base/ServiceRequestCheckout.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/service-request-payment",
    name: "ServiceRequestPayment",
    component: () => import("@/views/base/ServiceRequestPayment.vue"),
    meta: { role: ["anonymous"] },
  },
  {
    path: "/service-request-success",
    name: "ServiceRequestSuccess",
    component: () => import("@/views/base/ServiceRequestSuccess.vue"),
    meta: { role: ["anonymous"] },
  },
]
